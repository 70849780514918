import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import ButtonGhost from "../Button/ButtonGhost";

const About = ({ className, headingLevel }) => {
  const data = useStaticQuery(graphql`
    {
      team: file(relativePath: { eq: "repeating/about/team.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 651)
        }
      }
    }
  `);

  const HeadingTag = headingLevel || "h2";

  return (
    <section className={`${className || "mb-20 md:mb-32"}`}>
      <div className="container">
        <div className="grid md:grid-cols-12 md:gap-x-10 lg:gap-x-16 gap-y-8 items-center">
          <div className="md:col-start-1 md:col-span-7">
            <GatsbyImage image={data.team.childImageSharp.gatsbyImageData} />
          </div>
          <div className="md:col-end-13 md:col-span-5">
            <HeadingTag className="text-primary-800">
              Meet The MacAsh Family
            </HeadingTag>
            <p className="font-display italic text-xl md:text-2xl">
              We're a family-owned independent mortgage broker headed by a
              father and son duo. Everything we do is for our customers, who are
              truly the heart of our business.
            </p>
            <ButtonGhost
              href="/about/"
              text="Learn More"
              icon="far fa-arrow-right"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
