import React from "react";
import { useStaticQuery, graphql } from "gatsby";
// import { GatsbyImage } from "gatsby-plugin-image";

const ValueProps = ({ className, headingLevel, heading }) => {
  const data = useStaticQuery(graphql`
    {
      icon1: file(relativePath: { eq: "repeating/value-props/Icon1.svg" }) {
        publicURL
      }
      icon2: file(relativePath: { eq: "repeating/value-props/Icon2.svg" }) {
        publicURL
      }
      icon3: file(relativePath: { eq: "repeating/value-props/Icon3.svg" }) {
        publicURL
      }
    }
  `);

  const content = [
    {
      icon: data.icon1.publicURL,
      heading: "Fast",
      text: "We work FAST at MacAsh—our average time from application to close is just 15 days.",
    },
    {
      icon: data.icon2.publicURL,
      heading: "Simple",
      text: "Complete your loan entirely online in a simple, streamlined process that’s 100% secure.",
    },
    {
      icon: data.icon3.publicURL,
      heading: "Affordable",
      text: "Get custom, personalized rates and the best terms for an affordable loan to fit your budget.",
    },
  ];

  const HeadingTag = headingLevel || "h2";

  return (
    <section className={`${className || "mb-20 md:mb-32"}`}>
      <div className="container">
        <header className="md:max-w-2xl md:mx-auto md:text-center mb-10 md:mb-16">
          <HeadingTag>{heading || "Why MacAsh?"}</HeadingTag>
        </header>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-y-12 md:gap-x-10 md:text-center">
          {content.map((content, i) => {
            return (
              <div
                key={i}
                className="flex md:block items-start space-x-6 md:space-x-0"
              >
                <img
                  src={content.icon}
                  alt={content.heading}
                  className="md:mx-auto md:mb-2"
                />
                <div>
                  <h3 className="heading-five mb-2">{content.heading}</h3>
                  <p className="mb-0">{content.text}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default ValueProps;
